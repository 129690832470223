import { User } from '../../../models';
import { GET_USER_BY_ID_FAILURE, GET_USER_BY_ID_REQUEST, GET_USER_BY_ID_SUCCESS, PATCH_USERS_FAILURE, PATCH_USERS_REQUEST, PATCH_USERS_SUCCESS } from '../action-creators/users';
import { Action } from './index';

export interface ChatStore {
	selectedFriend?: User
    loading: boolean
}

const initialState: ChatStore = {
	selectedFriend: undefined,
    loading: true,
};

const ChatReducer = (state = initialState, action: Action): ChatStore => {
	const { type, payload } = action;

	switch (type) {
		case GET_USER_BY_ID_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_USER_BY_ID_SUCCESS:
			return {
				...state,
				selectedFriend: payload,
				loading: false,
			};
		case GET_USER_BY_ID_FAILURE:
			return {
				...state,
				loading: false,
			};
            case PATCH_USERS_REQUEST:
                return {
                    ...state,
                    loading: true,
                };
            case PATCH_USERS_SUCCESS:
                return {
                    ...state,
                    selectedFriend: payload,
                    loading: false,
                };
            case PATCH_USERS_FAILURE:
                return {
                    ...state,
                    loading: false,
                };    
		default:
			return state;
	}
};

export default ChatReducer;
