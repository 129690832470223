import { Avatar, Box, Card, Grid, ListItem, ListItemIcon, ListItemText, Typography, Badge, withStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { AuthStore } from "../../store/reducers/auth";
import DiscordInsert from "./DiscordInsert";

const ProfileCard = () => {
	const { me }: AuthStore = useSelector(({ auth }: RootState) => auth);

	return (
		<Card>
			<Box p={1}>
				<Grid container direction="column">
					<Grid md={3}>
						<Box>
							<Avatar alt="J" src={me?.profilePicPath}/>
						</Box>
					</Grid>
					<Grid md={8}>
						<Box textAlign="center">
							<Typography variant="h5" style={{fontFamily: "audiowide"}}>
								Connor Johnson
							</Typography>
						</Box>
					</Grid>
                    <Grid md={12}><DiscordInsert/></Grid>
				</Grid>
			</Box>
        </Card>
	);
};

export default ProfileCard;
