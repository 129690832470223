import { Avatar, Box, Card, Grid, ListItem, ListItemIcon, ListItemText, Typography, Badge, withStyles, FormControlLabel, FormGroup, Zoom, Fab, InputAdornment, Switch,  } from "@material-ui/core";
import { ReactNode, useState } from "react";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { tag } from "../../../utils/constant";
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Post from "./Post";

const PostDisplay = () => {
	const [alignment, setAlignment] = useState(true);

	const handleAlignment = (event: any, newAlignment: any) => {
	  setAlignment(newAlignment);
	};
	return (
		<Card>
			<Box p={1} display="flex">
				<Box display="flex" flexGrow={1}>
					<Box px={1}>
						<Autocomplete
							id="combo-box-demo"
							multiple
							size="small"
  							limitTags={2}
							options={tag}
							getOptionLabel={(option) => option.title}
							style={{ width: 700 }}
							renderInput={(params) => <TextField {...params} label="Add Tags" variant="outlined" />}
						/>		
					</Box>
				</Box>	
				<Box display="flex" >
					<Switch/>
				</Box>
			</Box>
        </Card>
	);
};

export default PostDisplay;
