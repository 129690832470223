import { Snack, User } from '../../../models';
import {
	GET_ME_FAILURE,
	GET_ME_REQUEST,
	GET_ME_SUCCESS,
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT,
	ONBOARD_FAILURE,
	ONBOARD_REQUEST,
	ONBOARD_SUCCESS,
	SET_SNACK_BAR,
} from '../action-creators/auth';
import { Action } from './index';

export interface AuthStore {
	token: string | null;
	loading: boolean;
	selectedNavbarItem?: string;
	me?: User;
	initialLoadFailed: boolean;
	snack: Snack;
	onboardingMessage: string;
}

const initialState: AuthStore = {
	token: localStorage.getItem('token'),
	loading: false,
	selectedNavbarItem: undefined,
	me: undefined,
	initialLoadFailed: false,
	snack: {
		open: false,
		severity: "",
		message: "Oops"
	},
	onboardingMessage: ""

};

const AuthReducer = (state = initialState, action: Action): AuthStore => {
	const { type, payload } = action;

	switch (type) {
		case LOGIN_REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				token: payload,
				loading: false,
			};
		case LOGIN_FAILURE:
			return {
				...state,
				loading: false,
			};
		case GET_ME_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_ME_SUCCESS:
			return {
				...state,
				loading: false,
				me: payload,
			};
		case GET_ME_FAILURE:
			return {
				...state,
				loading: false,
				initialLoadFailed: true,
			};
		case SET_SNACK_BAR:
			return {
				...state,
				snack: payload,
			}
		case ONBOARD_REQUEST:
			return {
				...state,
				loading: true,
			}
		case ONBOARD_SUCCESS:
			return {
				...state, 
				onboardingMessage: payload,
			}
		case ONBOARD_FAILURE:
			return {
				...state,
				loading: false,
			}
		case LOGOUT:
			localStorage.removeItem('token');
			return initialState;
		default:
			return state;
	}
};

export default AuthReducer;
