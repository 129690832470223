import { LoginForm, Onboard, User } from "../models";
import { http } from '../utils/http';

const ENDPOINT = 'auth';

export const login = async (body: LoginForm): Promise<string> => {
    const { data: token } = await http.post(`${ENDPOINT}/login`, body);
    return token;
  };
  
export const getMe = async (): Promise<User> => {
  const { data: me } = await http.get<User>(`${ENDPOINT}/me`);
  return me;
};

export const onboard = async (body: Onboard): Promise<string> => {
  const {data: result } = await http.post(`${ENDPOINT}/onboarding`, body)
  return result;
}