import { http } from '../utils/http';
import { User } from "../models"

const ENDPOINT = "Users"

export const getUser = async () => {
    const {data: newUser } = await http.get<User[]>(`${ENDPOINT}`)
    return newUser;}

export const getUserById = async (id: string ) => {
    const {data: newUser } = await http.get<User>(`${ENDPOINT}/${id}`)
    return newUser;
}

export const patchUser = async (user: User) => {
    const {data: newUser } = await http.patch<User>(`${ENDPOINT}`, user)
    return newUser;
}
