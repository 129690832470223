import { Avatar, Box, Card, Grid, ListItem, ListItemIcon, ListItemText, Typography, Badge, withStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { redirectToRoute } from "../../BrowserHistory";


interface FriendCardProps {
    profilePicPath: string;
    name: string;
	id: string;
}

const FriendCard = ({ profilePicPath, name, id }: FriendCardProps) => {
	return (
		<Card>
			<Box p={1}>
				<Grid container>
					<Grid md={2}>
						<Box>
						<Avatar alt="J" src={"https://www.google.com/imgres?imgurl=https%3A%2F%2Fmedia.istockphoto.com%2Fphotos%2Fflamy-symbol-picture-id178592315%3Fk%3D20%26m%3D178592315%26s%3D612x612%26w%3D0%26h%3DJJkPb9RM09MXwpapEhwkkL97rWgQjOLKFuj_GAbbCRE%3D&imgrefurl=https%3A%2F%2Fwww.istockphoto.com%2Fphotos%2Fflaming-c&tbnid=XuDJlrhGyNPm4M&vet=12ahUKEwia1NnA0vrzAhXWCc0KHWA5CuwQMygCegUIARDIAQ..i&docid=u8FrG2w-Uxbu8M&w=612&h=612&itg=1&q=flaming%20c&ved=2ahUKEwia1NnA0vrzAhXWCc0KHWA5CuwQMygCegUIARDIAQ"}/>
						</Box>
					</Grid>
					<Grid md={10}>
						<Box textAlign="center">
							<Typography variant="h5" style={{fontFamily: "audiowide"}}>
								Connor Johnson
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>
        </Card>
	);
};

export default FriendCard;
