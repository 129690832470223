import { Avatar, Box, Card, Divider, Grid, IconButton, Menu, MenuItem, MenuList, Paper, Typography } from "@material-ui/core"
import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { AuthStore } from "../store/reducers/auth";

export const Profile = () => {
    const { me }: AuthStore = useSelector(({ auth }: RootState) => auth);
return (<Box textAlign='center' height="90%">
        <Grid container>
            <Grid md={4}>
                <Box p={2}>
                    <Paper style={{height: "100%"}}>
                        <Box>
                            <Card>
                                <Grid container>
                                    <Grid md={2}>
                                        <Box p={1}>
                                            <Avatar style={{ width: 100, height: 100 }} src={me?.profilePicPath}>
                                            </Avatar>
                                        </Box>
                                    </Grid>
                                    <Grid md={10}>
                                        <Box p={1}>
                                            <Typography variant="h5" style={{fontFamily: "audiowide"}}>
                                                {me?.firstName} {me?.lastName}
                                            </Typography>
                                        </Box>
                                        <Typography variant="h6" style={{fontFamily: "audiowide"}}>
								            {me?.userName}#{me?.tag}
							            </Typography>
                                    </Grid>
                                    <Grid md={12}>
                                        <Box borderTop={1} p={1}>
                                            <Typography variant="h5" style={{fontFamily: "audiowide"}}>
                                                RANKINGS
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid md={8}>
                                        <MenuList>
                                            <MenuItem >COD Zombies</MenuItem>
                                            <MenuItem >Halo Campaign</MenuItem>
                                            <MenuItem >Cod Campaign</MenuItem>
                                        </MenuList>
                                    </Grid>
                                    <Grid md={4}>
                                       <MenuList>
                                            <MenuItem >1000</MenuItem>
                                            <MenuItem >1200</MenuItem>
                                            <MenuItem >1500</MenuItem>
                                        </MenuList>
                                    </Grid> 
                                </Grid>
                            </Card>
                        </Box>
                        <Divider/>
                    </Paper>
                </Box>
            </Grid>
            <Grid md={4}>
                <Box p={2}>
                    <Paper>
                        <Typography variant="h4" style={{fontFamily: "audiowide"}}>
                            ACHIEVEMENTS
                        </Typography>
                        <Divider/>
                    </Paper>
                </Box>
            </Grid>
            <Grid md={4}>
                <Box p={2}>
                    <Paper>
                        <Typography variant="h4" style={{fontFamily: "audiowide"}}>
                            CONNECT
                        </Typography>
                        <Divider/>
                        
                    </Paper>
                </Box>
            </Grid>
            <Grid md={8}>
                <Box p={2}>
                    <Paper>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Typography variant="h4" style={{fontFamily: "audiowide"}}>
                                    MY PORTFOLIO
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton>
                                    <EditIcon style={{color: "black"}}/>
                                </IconButton>
                            </Box>
                        </Box>
                        <Divider style={{color: "black"}}/>
                        Hello
                    </Paper>
                </Box>
            </Grid>
            <Grid md={4}>
                <Box p={2}>
                    <Paper>
                        <Typography variant="h4" style={{fontFamily: "audiowide"}}>
                            TOP RANKED GAMES
                        </Typography>
                        <Divider/>
                        
                    </Paper>
                </Box>
            </Grid>
        </Grid>
</Box>)}