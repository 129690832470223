import { Box, Button, Divider, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { Field, Form, Formik } from 'formik';
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { LoginForm } from "../models";
import { tryLogin } from "./store/actions/auth";
import GoogleLogo from '../google-logo.png';


const Login = () => {
    const dispatch = useDispatch();
    const responseGoogle = (response: any) => {
		dispatch(
			tryLogin({
				token: response.tokenId,
				type: 'Google',
			})
		);
	};
    return (
    <Box height='100vh' style={{backgroundColor: '#252024'}}>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '90%' }}
        >
            <Grid item xs={3}>            
                <Paper>
                    <Box>
                    <GoogleLogin
						clientId='86413558378-79ikq8e78pi8qlpo0fok2vbfgd5o8uhj.apps.googleusercontent.com'
						buttonText='Login With Google'
						onSuccess={responseGoogle}
						onFailure={responseGoogle}
						cookiePolicy={'single_host_origin'}
						render={({ onClick, disabled }) => (
							<Button
								variant='contained'
								onClick={onClick}
								style={{
									backgroundColor: 'white',
									padding: 15,
								}}
							>
								<img src={GoogleLogo} style={{ marginRight: 10, width: 20 }} alt="Google" />
								<Typography style={{ letterSpacing: 1.1 }}>Login With Google</Typography>
							</Button>
						)}
					/>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </Box>
    )
}

export default Login;