import { Box, Button, Divider, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { redirectToRoute } from "../../../BrowserHistory";
import { Onboard } from "../../../models";
import { onboard } from "../../../services/authservice";
import { validateUsername } from "../../../utils/validator";
import { setSnackBar } from "../../store/action-creators/auth";
import { tryOnboard } from "../../store/actions/auth";
import { tryPatchUser } from "../../store/actions/users";
import { RootState } from "../../store/reducers";
import { AuthStore } from "../../store/reducers/auth";


const Onboarding = () => {
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(false);
    const { me, onboardingMessage}: AuthStore = useSelector(({ auth }: RootState) => auth);
    const [newUser, setNewUser] = useState(me);
    // dispatch(tryPatchUser(me));
    // redirectToRoute("/")
    useEffect(() => {
        if (flag != false) {
            console.log(onboardingMessage)
            if (onboardingMessage == "successful") {
                if (newUser != undefined) {
                    dispatch(tryPatchUser(newUser))
                }}
        } else { 
            setFlag(true)

        }
    }, [onboardingMessage])
    if (!me) return null;
    return (
    <Box height='100vh' style={{backgroundColor: '#252024'}}>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '90%' }}
        >
            <Grid item xs={3}>            
                <Paper>
                    <Box p={1}>
                        <Box textAlign="center">
                            <Typography variant="h6" style={{fontFamily: "audiowide"}}>YOU'RE ALMOST</Typography>
                        </Box>
                        <Box textAlign="center">
                            <Typography variant="h6" style={{fontFamily: "audiowide"}}>THERE </Typography>
                        </Box>
                        <Formik
                            initialValues={{
                                userName: ""
                            }}
                            onSubmit={(values)=> {
                                me.userName = values.userName;
                                const tag = Math.floor(Math.random() * 10000)
                                if (values.userName == "" || values.userName == undefined) {
                                    dispatch(setSnackBar({open: true, message: "UserName can't be empty", severity: "Error"}))
                                    return;
                                }
                                if (validateUsername(me.userName) != "") {
                                    dispatch(setSnackBar({open: true, message: validateUsername(me.userName), severity: "Error"}))
                                }
                                const onboard = {
                                    userName: me.userName,
                                    tag: tag.toString()
                                } as Onboard
                                setNewUser({...me, userName: onboard.userName, tag: onboard.tag})
                                dispatch(tryOnboard(onboard))
                            }}
                            >
                               {({ values, handleSubmit, handleChange}) => (
					            <Form> 
                                    <Field
                                        fullWidth
										name='userName'
										onChange={handleChange}
										value={values.userName}
										variant='filled'
										label='User Name'
										as={TextField}/>
                                    <Box textAlign="center" p={1}>
                                        <Button variant="contained" onClick={() => handleSubmit()} style={{backgroundColor: "#ac1a1a", color: "white"}}>
                                            Create Account
                                        </Button>
                                    </Box>
                                </Form>
                               )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </Box>
    )
}

export default Onboarding;