import { User } from "../../../models";

export const GET_FRIEND_BY_ID_REQUEST = 'GET_FRIEND_BY_ID_REQUEST';
export const getFriendByIdRequest = () => ({ type: GET_FRIEND_BY_ID_REQUEST });

export const GET_FRIEND_BY_ID_SUCCESS = 'GET_FRIEND_BY_ID_SUCCESS';
export const getFriendByIdSuccess = (friend: User) => ({
	type: GET_FRIEND_BY_ID_SUCCESS,
	payload: friend,
});

export const GET_FRIEND_BY_ID_FAILURE = 'GET_FRIEND_BY_ID_FAILURE';
export const getFriendByIdFailure = () => ({ type: GET_FRIEND_BY_ID_FAILURE });