import { Dispatch } from 'redux';
import { Post } from '../../../models';
import * as postService from '../../../services/postService';
import { setSnackBar } from '../action-creators/auth';
import { getPostByIdFailure, getPostByIdRequest, getPostByIdSuccess, getPostsFailure, getPostsRequest, getPostsSuccess, patchPostsFailure, patchPostsRequest, patchPostsSuccess, postPostsFailure, postPostsRequest, postPostsSuccess } from '../action-creators/post';



export const tryGetPostId =
	(body: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(getPostByIdRequest());
			const friend = await postService.getPostById(body);
			dispatch(getPostByIdSuccess(friend));
		} catch (err) {
			console.error(err);
			dispatch(getPostByIdFailure());
		}
	};

export const tryPatchPost =
	(body: Post) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(patchPostsRequest());
			const friend = await postService.patchPost(body);
			dispatch(patchPostsSuccess(friend));
		} catch (err) {
			console.error(err);
			dispatch(patchPostsFailure());
		}
	};

export const tryGetPosts =
	(pageNumber: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(getPostsRequest());
			const friend = await postService.getPosts();
			dispatch(getPostsSuccess(friend));
		} catch (err) {
			console.error(err);
			dispatch(getPostsFailure());
		}
	};

export const tryPostPost = (post: Post) =>
	async (dispatch: Dispatch): Promise<void> => {
		try { 
			dispatch(postPostsRequest());
			const newPost = await postService.postPost(post)
			dispatch(postPostsSuccess(newPost));
		} catch (err) {
			console.error(err);
			dispatch(postPostsFailure());
			dispatch(setSnackBar({open: true, message: "Failed to Post", severity: "Error"}))
		}
	}