import { User } from '../../../models';
import { GET_ME_REQUEST, GET_ME_SUCCESS, GET_ME_FAILURE, LOGOUT } from '../action-creators/auth';
import { GET_FRIEND_BY_ID_REQUEST, GET_FRIEND_BY_ID_SUCCESS, GET_FRIEND_BY_ID_FAILURE } from '../action-creators/chat';
import { Action } from './index';

export interface ChatStore {
	selectedFriend?: User
    loading: boolean
}

const initialState: ChatStore = {
	selectedFriend: undefined,
    loading: true,
};

const ChatReducer = (state = initialState, action: Action): ChatStore => {
	const { type, payload } = action;

	switch (type) {
		case GET_FRIEND_BY_ID_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_FRIEND_BY_ID_SUCCESS:
			return {
				...state,
				selectedFriend: payload,
				loading: false,
			};
		case GET_FRIEND_BY_ID_FAILURE:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default ChatReducer;
