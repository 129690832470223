import { Avatar, Box, Drawer, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { redirectToRoute } from '../../BrowserHistory';
import DrawerListItem from './DrawerList';
import{ Home } from '@material-ui/icons'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

interface DrawerListItemProps {
	drawerOpen: boolean;
	setDrawerOpen: (open: boolean) => void;

}

const MainDrawer = ({ setDrawerOpen, drawerOpen}: DrawerListItemProps) => {
	return (
		<Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
			<Box display="flex">
				<Avatar>CJ</Avatar>
					<Box style={{ marginLeft: 16 }}>
						<Typography>
							<b>
								Connor Johnson
							</b>
						</Typography>
					</Box>
			</Box>
			<DrawerListItem icon={<Home/>} route='/' closeDrawer={() => setDrawerOpen} text='Dashboard'/>
			<DrawerListItem icon={<SportsEsportsIcon/>} route='/schoolDashboard' closeDrawer={() => setDrawerOpen} text='Tournaments'/>
        </Drawer>
	);
};

export default MainDrawer;
