import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { redirectToRoute } from '../../BrowserHistory';

interface DrawerListItemProps {
	icon: ReactNode;
	route: string;
	closeDrawer: () => void;
	text: string;
}

const DrawerListItem = ({ icon, route, closeDrawer, text }: DrawerListItemProps) => {
	return (
		<ListItem
			button
			key={text}
			onClick={() => {
				closeDrawer();
				redirectToRoute(route);
			}}
		>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={text} />
		</ListItem>
	);
};

export default DrawerListItem;
