import axios from 'axios';

const defaultOptions = {
	baseURL: 'https://gamentellapi.azurewebsites.net',
	headers: {
		'Content-Type': 'application/json',
	},
};

export const http = axios.create(defaultOptions);

http.interceptors.request.use((config) => {
	const token = localStorage.getItem('token');
    if (config.headers) {
	    config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
	return config;
});

export default http;
