import React from 'react';
import logo from './logo.svg';
import './App.css';
import Root from './Root';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Route, Router, Switch } from 'react-router-dom';
import Login from './pages/Login';
import { Slide, Snackbar } from '@material-ui/core';
import { RootState } from './pages/store/reducers';
import { AuthStore } from './pages/store/reducers/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackBar } from './pages/store/action-creators/auth';
import { history } from './BrowserHistory';
import Onboarding from './pages/components/dashboard/Onboarding';


function App() {
  const dispatch = useDispatch();
  const { snack }: AuthStore = useSelector(({ auth }: RootState) => auth);
  function SlideTransition(props: any) {
    return <Slide {...props} direction="left" />;
  }

  const appTheme = createTheme({
    palette: {
      primary: {
        main: '#ac1a1a'
      }
    }
  });

  return (
    <MuiThemeProvider theme={appTheme}>
      <Router history={history}>
        <Switch>
          <Route path='/login'>
            <Login/>
          </Route>
          <Route path="/onboarding">
            <Onboarding/>
          </Route>
          <Route path='/'>
            <Root/>
          </Route>
        </Switch>
        </Router>
        <Snackbar
          open={snack.open}
          autoHideDuration={2000}
          anchorOrigin={{vertical: "top", horizontal: "right"}}
          message={snack.message}
          TransitionComponent={SlideTransition}
          onClose={() => {
            dispatch(setSnackBar({...snack, open: false}))
          }}
			/>
    </MuiThemeProvider>
  );
}

export default App;
