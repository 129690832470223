import { Dispatch } from 'redux';
import { User } from '../../../models';
import * as userService from '../../../services/userService';
import { getUserByIdFailure, getUserByIdRequest, getUserByIdSuccess, getUsersFailure, getUsersRequest, getUsersSuccess, patchUsersFailure, patchUsersRequest, patchUsersSuccess } from '../action-creators/users';



export const tryGetUserId =
	(body: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(getUserByIdRequest());
			const friend = await userService.getUserById(body);
			dispatch(getUserByIdSuccess(friend));
		} catch (err) {
			console.error(err);
			dispatch(getUserByIdFailure());
		}
	};

export const tryPatchUser =
	(body: User) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(patchUsersRequest());
			const friend = await userService.patchUser(body);
			dispatch(patchUsersSuccess(friend));
		} catch (err) {
			console.error(err);
			dispatch(patchUsersFailure());
		}
	};

export const tryGetUsers =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(getUsersRequest());
			const friend = await userService.getUser();
			dispatch(getUsersSuccess(friend));
		} catch (err) {
			console.error(err);
			dispatch(getUsersFailure());
		}
	};