import { Post } from "../../../models";

export const GET_POST_BY_ID_REQUEST = 'GET_POST_BY_ID_REQUEST';
export const getPostByIdRequest = () => ({ type: GET_POST_BY_ID_REQUEST });

export const GET_POST_BY_ID_SUCCESS = 'GET_POST_BY_ID_SUCCESS';
export const getPostByIdSuccess = (user: Post) => ({
	type: GET_POST_BY_ID_SUCCESS,
	payload: user,
});

export const GET_POST_BY_ID_FAILURE = 'GET_POST_BY_ID_FAILURE';
export const getPostByIdFailure = () => ({ type: GET_POST_BY_ID_FAILURE });

export const GET_POSTS_REQUEST = 'GET_POSTS_REQUEST';
export const getPostsRequest = () => ({ type: GET_POSTS_REQUEST });

export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS';
export const getPostsSuccess = (users: Post[]) => ({
	type: GET_POSTS_SUCCESS,
	payload: users,
});

export const GET_POSTS_FAILURE = 'GET_POSTS_FAILURE';
export const getPostsFailure = () => ({ type: GET_POSTS_FAILURE });

export const POST_POSTs_REQUEST = 'POST_POSTs_REQUEST';
export const postPostsRequest = () => ({ type: POST_POSTs_REQUEST });

export const POST_POSTs_SUCCESS = 'POST_POSTs_SUCCESS';
export const postPostsSuccess = (user: Post) => ({
	type: POST_POSTs_SUCCESS,
	payload: user,
});

export const POST_POSTs_FAILURE = 'POST_POSTs_FAILURE';
export const postPostsFailure = () => ({ type: POST_POSTs_FAILURE });

export const PATCH_POSTS_REQUEST = 'PATCH_POSTS_REQUEST';
export const patchPostsRequest = () => ({ type: PATCH_POSTS_REQUEST });

export const PATCH_POSTS_SUCCESS = 'PATCH_POSTS_SUCCESS';
export const patchPostsSuccess = (user: Post) => ({
	type: PATCH_POSTS_SUCCESS,
	payload: user,
});

export const PATCH_POSTS_FAILURE = 'PATCH_POSTS_FAILURE';
export const patchPostsFailure = () => ({ type: PATCH_POSTS_FAILURE });