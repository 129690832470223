import { createBrowserHistory } from 'history';

export const history = createBrowserHistory()

export const redirectToRoute = (url: string) => {
	history.push(url);
};

export const redirectToLogin = () => {
	history.replace('/login', { from: history.location });
};

export const goBackOrGoHome = () => {
	if ((history.location.state as any)?.from?.pathname) {
		history.replace((history.location.state as any).from.pathname as string, undefined);
	} else {
		history.push('/');
	}
};