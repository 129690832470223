import { Box, CircularProgress, Grid, Typography  } from "@material-ui/core";

const Matched = () => {
    return (<Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '90%' }}
      >
        <Grid item xs={3}>            
            <Box textAlign='center'>
                <CircularProgress thickness={4.5} size={100}/>
                <Typography variant='h6' style={{color: 'white'}}>Looking for a Match...</Typography>
            </Box>
        </Grid>
    </Grid>)
} 

export default Matched;