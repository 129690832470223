
import { combineReducers } from 'redux';
import auth from './auth';
import chat from './chat';
import posts from './posts';
import users from './users';

export interface Action {
	type: string;
	payload?: any;
}

const rootReducer = combineReducers({
	auth,
	chat,
	posts,
	users
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
