import { Dispatch } from 'redux';
import { redirectToRoute } from '../../../BrowserHistory';
import { LoginForm, Onboard, User } from '../../../models';
import {  getMeFailure, getMeRequest, getMeSuccess, loginFailure, loginRequest, loginSuccess, onboardFailure, onboardRequest, onboardSuccess, setSnackBar } from '../action-creators/auth';
import * as authService from '../../../services/authservice';


export const tryOnboard =
	(body: Onboard) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(onboardRequest());
			const token = await authService.onboard(body);
			if (token != "successful") {
				dispatch(setSnackBar({open: true, message: token, severity: "Error"}))
			}
			dispatch(onboardSuccess(token));
			redirectToRoute('/');
		} catch (err) {
			console.error(err);
			dispatch(setSnackBar({open: true, message: "Onboard Failed, Try again Later", severity: "Error"}))
			dispatch(onboardFailure());
		}
	};

export const tryLogin =
	(body: LoginForm) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(loginRequest());
			const token = await authService.login(body);
			dispatch(loginSuccess(token));
			localStorage.setItem('token', token);
			dispatch(setSnackBar({open: true, message: "Login Successful", severity: "Error"}))
			redirectToRoute('/');
			console.log("Redirected");
		} catch (err) {
			console.error(err);
			dispatch(setSnackBar({open: true, message: "Login Failed", severity: "Error"}))
			dispatch(loginFailure());
		}
	};

export const tryGetMe =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(getMeRequest());
			const me: User = await authService.getMe();
			dispatch(getMeSuccess(me));
		} catch (err) {
			dispatch(getMeFailure());
			redirectToRoute('/login');
		}
	};
