import { Box, Button, Card, Grid, IconButton, TextField } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Post, Snack } from "../../../models";
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { Autocomplete } from "@material-ui/lab";
import { tag } from "../../../utils/constant";
import * as azureService from "../../../services/azureService";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackBar } from "../../store/action-creators/auth";
import { tryPostPost } from "../../store/actions/post";
import { AuthStore } from "../../store/reducers/auth";
import { RootState } from "../../store/reducers";

const NewPostForm = () => {
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);
    const [blobList, setBlobList] = useState<string[]>([]);
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [imageString, setImageString] = useState("");
    const { me}: AuthStore = useSelector(({ auth }: RootState) => auth);

 
    const onFileChange = (event: any) => {
        // Update the stat
        setSelectedFile(event.target.files[0]);
        
        var reader = new FileReader();
        console.log(selectedFile)
        if (selectedFile) {
            dispatch(setSnackBar({
                message: "File Upload Successful",
                severity: "success",
                open: true
            } as Snack))
            reader.readAsDataURL(selectedFile);
            reader.onload = function () {
                if (reader.result) {
                    setImageString(reader.result?.toString());
                }
            };
        } else {
            dispatch(setSnackBar({
                message: "File Upload Failed",
                severity: "error",
                open: true
            } as Snack))
        }
    }
    if (!me) return null

    return (
        <Card>
            <Formik
                initialValues={{
                    Id: "",
                    UserName: "",
                    Tag: "",
                    UserId: "",
                    Votes: 0,
	                PostText: "",
	                Image: "",
	                comments: []
                } as Post}
                onSubmit={(values) => {
                   values.Image = imageString;
                   values.UserId = me.id;
                   values.UserName = me.userName;
                    dispatch(tryPostPost(values as Post))
                }}
            >
                {({ values, handleSubmit, handleChange }) => (
                    <Form>
                        <Grid container>
                            <Grid md={12}>
                                <Field
                                    fullWidth
                                    name='PostText'
                                    value={values.PostText}
                                    multiline
                                    rows={8}
                                    onChange={handleChange}
                                    variant='filled'
                                    label='Text'
                                    as={TextField}
                                />
                            </Grid>
                                <Grid md={1}>
                                    <Box padding={.9}>
                                        <input accept="image/*" style={{display: "none"}} id="icon-button-file" type="file" onChange={onFileChange}/>
                                        <label htmlFor="icon-button-file">
                                            <AddPhotoAlternateIcon fontSize="large"/>
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid md={6}>
                                    <Box paddingTop={.4}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            size="small"
                                            options={tag}
                                            style={{width: 400}}
                                            getOptionLabel={(option) => option.title}
                                            renderInput={(params) => <TextField {...params} label="Add Tags" variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid md={5}>
                                    <Box textAlign="right"  paddingTop={.5} paddingRight={1}>
                                        <Button variant="contained" onClick={() => handleSubmit()} style={{ backgroundColor: "#ac1a1a", color: "white" }}>
                                            Post
                                        </Button>
                                    </Box>
                                </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default NewPostForm;