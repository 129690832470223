import { User } from "../../../models";

export const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST';
export const getUserByIdRequest = () => ({ type: GET_USER_BY_ID_REQUEST });

export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const getUserByIdSuccess = (user: User) => ({
	type: GET_USER_BY_ID_SUCCESS,
	payload: user,
});

export const GET_USER_BY_ID_FAILURE = 'GET_USER_BY_ID_FAILURE';
export const getUserByIdFailure = () => ({ type: GET_USER_BY_ID_FAILURE });

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const getUsersRequest = () => ({ type: GET_USERS_REQUEST });

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const getUsersSuccess = (users: User[]) => ({
	type: GET_USERS_SUCCESS,
	payload: users,
});

export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const getUsersFailure = () => ({ type: GET_USERS_FAILURE });

export const PATCH_USERS_REQUEST = 'PATCH_USERS_REQUEST';
export const patchUsersRequest = () => ({ type: PATCH_USERS_REQUEST });

export const PATCH_USERS_SUCCESS = 'PATCH_USERS_SUCCESS';
export const patchUsersSuccess = (user: User) => ({
	type: PATCH_USERS_SUCCESS,
	payload: user,
});

export const PATCH_USERS_FAILURE = 'PATCH_USERS_FAILURE';
export const patchUsersFailure = () => ({ type: PATCH_USERS_FAILURE });