import { Box, Button, Card, Typography, Grid, Paper, Dialog, Fab } from "@material-ui/core";
import { redirectToRoute } from "../BrowserHistory";
import PostDisplay from "./components/dashboard/PostDisplay";
import ProfileCard from "./components/dashboard/ProfileCard";
import AddIcon from '@material-ui/icons/Add';
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { tryGetPosts } from "./store/actions/post";
import { RootState } from "./store/reducers";
import { PostStore } from "./store/reducers/posts";
import NewPostForm from "./components/dashboard/NewPostForm";

const Dashboard = () => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)

    const { posts, pageNumber }: PostStore = useSelector(({ posts }: RootState) => posts);

    useEffect(() => {
        dispatch(tryGetPosts(pageNumber))
    }, [])

    return ( 
    <Box textAlign='center'>
        <Fab style={{position: 'fixed', zIndex: 10, bottom: '10px', right: '10px', margin: 0, padding: 0}} onClick={() => setIsOpen(true)}>
			<AddIcon/>
		</Fab>
        <Box height='90vh' style={{backgroundColor: '#252024'}}>
        <Grid
            container
            spacing={0}
            style={{ minHeight: '90%' }}
        >
            <Grid item md={4}> 
               <Box p={1}>
                    <Paper style={{minHeight: "100%"}}>
                        <ProfileCard/>
                    </Paper>
               </Box>
            </Grid>
            <Grid item md={8}>
                <Box p={1}>
                    <PostDisplay/>
                    <Box py={1}>
                        <Paper style={{minHeight: "100%"}}>
                            <InfiniteScroll 
                                dataLength={["", "hello"].length} //This is important field to render the next data
                                next={() => dispatch(posts)}
                                hasMore={true}
                                loader={<h4>Loading Posts...</h4>}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                    <b>Yay! You have seen it all</b>
                                    </p>
                            }>
                            </InfiniteScroll>
                        </Paper>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    </Box> 
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <NewPostForm/>
    </Dialog>
    </Box>
    )}


export default Dashboard;