import { http } from '../utils/http';
import { Post } from "../models"
import { Https } from '@material-ui/icons';

const ENDPOINT = "Post"

export const getPosts = async () => {
    const {data: posts } = await http.get<Post[]>(`${ENDPOINT}`)
    return posts;}

export const getPostById = async (id: string ) => {
    const {data: post } = await http.get<Post>(`${ENDPOINT}/${id}`)
    return post;
}

export const patchPost = async (user: Post) => {
    const {data: newPost } = await http.patch<Post>(`${ENDPOINT}`, user)
    return newPost;
}

export const postPost = async (post: Post) => {
    const {data: newPost } = await http.post<Post>(`${ENDPOINT}`, post)
    return newPost
}

