import { AppBar, Box, Button, Card, IconButton, Toolbar, Typography, Avatar, Drawer, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Dialog, Fab } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { Router, Switch, Route } from 'react-router-dom';
import { history, redirectToRoute } from './BrowserHistory';
import { useEffect, useRef, useState } from "react";
import SettingsIcon from '@material-ui/icons/Settings';
import LockIcon from '@material-ui/icons/Lock';
import Dashboard from "./pages/Dashboard";
import Matchmaking from "./pages/TournamentPages/Matchmaking";
import MainDrawer from "./pages/components/Drawer";
import Matched from "./pages/TournamentPages/Matched";
import Login from "./pages/Login";
import { tryGetMe } from "./pages/store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { AuthStore } from "./pages/store/reducers/auth";
import { RootState } from "./pages/store/reducers";
import { Profile } from "./pages/profile/Profile";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FriendsPageList from "./pages/profile/FriendsPage";
import { logout, LOGOUT } from "./pages/store/action-creators/auth";
import Onboarding from "./pages/components/dashboard/Onboarding";
import AddIcon from '@material-ui/icons/Add';



const Root = () => {
    const dispatch = useDispatch();
    const [profile, setProfile] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
	const avatarRef = useRef<HTMLButtonElement | null>(null);
    const { me }: AuthStore = useSelector(({ auth }: RootState) => auth);

    useEffect(() => {
        dispatch(tryGetMe());
    },[])

    useEffect(() => {
        if (!me) return;
        if (me?.userName == "" || me?.userName == undefined) {
            redirectToRoute("/onboarding")
        }
    }, [me])


    return (

         <Box height='100vh' style={{backgroundColor: '#252024'}}>
             {me && <><AppBar position="static" color="primary">
                <Toolbar color='#ac1a1a'>
                    {/* <IconButton onClick={() => setDrawerOpen(true)}>
                        <MenuIcon />
                    </IconButton> */}
                    <Box onClick={() => {redirectToRoute("/")}}>
                        <img src="Game'ntell logo.png" alt="logo" height = '50px'/>
                    </Box>
                    <Box flexGrow={1} textAlign='right'>
                        <IconButton onClick={() => {redirectToRoute("/messages")}}>
                            <ChatBubbleIcon fontSize="large"/>
                        </IconButton>
                        <IconButton onClick={() => setProfile(true)} ref={avatarRef}>
                            <Avatar src={me.profilePicPath}/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar> 
            <Router history=
            {history}>
                <Switch>
                    <Route path='/Matchmaking'>
                        <Matchmaking/>
                    </Route>
                    <Route path='/Matched'>
                        <Matched/>
                    </Route>
                    <Route path="/Profile">
                        <Profile/>
                    </Route>
                    <Route path="/messages">
                        <FriendsPageList/>
                    </Route>
                    <Route path='/'>
                        <Dashboard/>
                    </Route>
                </Switch>
            </Router>
            <Popper open={profile} anchorEl={avatarRef.current} placement={'bottom-start'} transition style={{ zIndex: 999999999 }}>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={() => setProfile(false)}>
								<MenuList>
                                    <Box p={1}>
                                        <Button endIcon={<SettingsIcon/>} fullWidth onClick={() => {redirectToRoute('profile')}}>
                                            Settings
                                        </Button>
                                    </Box>
                                    <Box p={1}>
                                        <Button endIcon={<LockIcon/>} fullWidth onClick={() => {dispatch(logout()); redirectToRoute("/login")}}>
                                            Log out
                                        </Button>
                                    </Box>
                                </MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
            <MainDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>  
            </>}     
        </Box>
    )
}

export default Root;