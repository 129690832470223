import React from "react";
import { Snack, User } from "../../../models";
export const ONBOARD_REQUEST = 'ONBOARD_REQUEST';
export const onboardRequest = () => ({ type: ONBOARD_REQUEST });

export const ONBOARD_SUCCESS = 'ONBOARD_SUCCESS';
export const onboardSuccess = (token: string) => ({
	type: ONBOARD_SUCCESS,
	payload: token,
});

export const ONBOARD_FAILURE = 'ONBOARD_FAILURE';
export const onboardFailure = () => ({ type: ONBOARD_FAILURE });


export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const loginRequest = () => ({ type: LOGIN_REQUEST });

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const loginSuccess = (token: string) => ({
	type: LOGIN_SUCCESS,
	payload: token,
});

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const loginFailure = () => ({ type: LOGIN_FAILURE });

export const LOGOUT = 'LOGOUT';
export const logout = () => ({ type: LOGOUT });

export const GET_ME_REQUEST = 'GET_ME_REQUEST';
export const getMeRequest = () => ({ type: GET_ME_REQUEST });

export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const getMeSuccess = (user: User) => ({
	type: GET_ME_SUCCESS,
	payload: user,
});

export const GET_ME_FAILURE = 'GET_ME_FAILURE';
export const getMeFailure = () => ({ type: GET_ME_FAILURE });

export const SET_SNACK_BAR = "SET_SNACK_BAR";
export const setSnackBar = (snack: Snack) => ({
	type: SET_SNACK_BAR,
	payload: snack
})