import { Box, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { redirectToRoute } from '../../BrowserHistory';
import FriendCard from '../components/FriendCard';
import AddIcon from '@material-ui/icons/Add';

const FriendsPageList = () => {
	return (
		<Box style={{height: "89%"}} p={1}>
			<Grid container>
				<Grid md={3}>
					<Box p={1}>
						<Paper style={{height: "100%"}}>
							<Box display="flex">
								<Box textAlign="center" display="inline" flexGrow={1}>
									<Typography variant="h5" style={{fontFamily: "audiowide"}}>Friends</Typography>
								</Box>
								<IconButton>
										<AddIcon fontSize="small" style={{color: "black"}}/>
								</IconButton>
							</Box>
						</Paper>
						<Box py={1}>
							<FriendCard profilePicPath="" name="Connor Johnson" id="ID"/>
						</Box>
					</Box>
				</Grid>
				<Grid md={9}>
					<Box p={1}>
						<Paper style={{height: "100%"}}>
							<Box style={{height: "100%"}}>
								Hello
							</Box>
						</Paper>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default FriendsPageList;
