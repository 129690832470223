import { Post } from '../../../models';
import { GET_POSTS_FAILURE, GET_POSTS_REQUEST, GET_POSTS_SUCCESS, GET_POST_BY_ID_FAILURE, GET_POST_BY_ID_REQUEST, GET_POST_BY_ID_SUCCESS, PATCH_POSTS_FAILURE, PATCH_POSTS_REQUEST, PATCH_POSTS_SUCCESS } from '../action-creators/post';
import { Action } from './index';

export interface PostStore {
	posts?: Post[]
    selectedPost: Post | undefined
    loading: boolean
    pageNumber: number

}

const initialState: PostStore = {
	posts: [],
    loading: true,
    selectedPost: undefined,
    pageNumber: 1
};

const PostReducer = (state = initialState, action: Action): PostStore => {
	const { type, payload } = action;

	switch (type) {
		case GET_POST_BY_ID_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_POST_BY_ID_SUCCESS:
			return {
				...state,
				selectedPost: payload,
				loading: false,
			};
		case GET_POST_BY_ID_FAILURE:
			return {
				...state,
				loading: false,
			};
            case PATCH_POSTS_REQUEST:
                return {
                    ...state,
                    loading: true,
                };
            case PATCH_POSTS_SUCCESS:
                return {
                    ...state,
                    selectedPost: payload,
                    loading: false,
                };
            case PATCH_POSTS_FAILURE:
                return {
                    ...state,
                    loading: false,
                };
            case GET_POSTS_REQUEST:
                return {
                    ...state,
                    loading: true,
                };
            case GET_POSTS_SUCCESS:
                return {
                    ...state,
                    posts: payload,
                    loading: false,
                    pageNumber: state.pageNumber + 1
                };
            case GET_POSTS_FAILURE:
                return {
                    ...state,
                    loading: false,
                };    
		default:
			return state;
	}
};

export default PostReducer;
